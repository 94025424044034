import {
  SEND_PASSWORD_RESET_LINK,
  VERIFY_PASSWORD_RESET_KEY,
  RESET_PASSWORD,
} from "@/graphql/mutations/authPasswordReset";
import { apolloClient } from "@/plugins/apollo";

const authPasswordResetApi = {
  async sendResetLink(loginEmail) {
    const { data } = await apolloClient.mutate({
      mutation: SEND_PASSWORD_RESET_LINK,
      variables: {
        payload: {
          loginEmail,
        },
      },
    });
    return data.sendPasswordResetLink;
  },

  async verifyResetKey(resetKey) {
    const { data } = await apolloClient.mutate({
      mutation: VERIFY_PASSWORD_RESET_KEY,
      variables: {
        payload: {
          resetKey,
        },
      },
    });
    return data.verifyPasswordResetKey;
  },

  async resetPassword(resetKey, newPassword) {
    const { data } = await apolloClient.mutate({
      mutation: RESET_PASSWORD,
      variables: {
        payload: {
          resetKey,
          newPassword,
        },
      },
    });
    return data.resetPassword;
  },
};

export default authPasswordResetApi;
