import gql from "graphql-tag";

export const SEND_PASSWORD_RESET_LINK = gql`
  mutation SendPasswordResetLink($payload: SendPasswordResetLinkInput!) {
    sendPasswordResetLink(payload: $payload)
  }
`;

export const VERIFY_PASSWORD_RESET_KEY = gql`
  mutation VerifyPasswordResetKey($payload: VerifyPasswordResetKeyInput!) {
    verifyPasswordResetKey(payload: $payload)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($payload: ResetPasswordInput!) {
    resetPassword(payload: $payload)
  }
`;
