import authPasswordResetService from "@/services/authPasswordResetService";

export default function usePasswordReset() {
  const ERROR_CODE = {
    LINK_EXPIRED: "LINK_EXPIRED",
    WRONG_KEY: "WRONG_KEY",
  };

  const sendPasswordResetLink = async (loginEmail) => {
    return await authPasswordResetService.sendResetLink(loginEmail);
  };

  const verifyResetKey = async (resetKey) => {
    return await authPasswordResetService.verifyResetKey(resetKey);
  };

  const resetPassword = async (resetKey, newPassword) => {
    return await authPasswordResetService.resetPassword(resetKey, newPassword);
  };

  return {
    sendPasswordResetLink,
    verifyResetKey,
    resetPassword,
    ERROR_CODE,
  };
}
