import authPasswordResetApi from "@/api/authPasswordReset";

const authPasswordResetService = {
  async sendResetLink(loginEmail) {
    console.log(">>>SEND PASSWORD RESET LINK<<<");
    return await authPasswordResetApi.sendResetLink(loginEmail);
  },

  async verifyResetKey(resetKey) {
    console.log(">>>VERIFY PASSWORD RESET KEY<<<");
    return await authPasswordResetApi.verifyResetKey(resetKey);
  },

  async resetPassword(resetKey, newPassword) {
    console.log(">>>RESET PASSWORD: UPDATE WITH NEW ONE<<<");
    return await authPasswordResetApi.resetPassword(resetKey, newPassword);
  },
};

export default authPasswordResetService;
