<template>
  <div class="container">
    <LayoutViewWrapperNarrow>
      <div class="content">
        <BaseH1 :text="'Resetowanie hasła'" :short="true" />
        <TextDescription>
          Wiadomość z linkiem do zmiany hasła została wysłana pod adres
          <b style="font-style: italic">
            {{ loginEmail }}
          </b>
          <br />
          <br />
          Proszę sprawdź swoją skrzynkę
        </TextDescription>
        <ButtonLink :label="'Wyślij link ponownie'" @click="handleResendLink" />
      </div>
    </LayoutViewWrapperNarrow>
    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import usePasswordReset from "@/composables/usePasswordReset";
import useIsMobile from "@/composables/useIsMobile";
import { ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME } from "@/router/constants";

import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import NavMobile from "@/components/NavMobile.vue";

const ROUTE_PASSWORD_RESET_FORGOT_PASSWORD = {
  name: ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME,
};

export default {
  components: {
    LayoutViewWrapperNarrow,
    BaseH1,
    TextDescription,
    ButtonLink,
    NavMobile,
  },

  setup() {
    const { isMobile } = useIsMobile();
    const { sendPasswordResetLink } = usePasswordReset();
    const route = useRoute();
    const router = useRouter();

    const loginEmail = computed(() => {
      return route.query.email || null;
    });

    const handleResendLink = async () => {
      try {
        await sendPasswordResetLink(loginEmail.value);
        // await router.push(routePasswordResetLink.value);
      } catch (error) {
        alert(error.message);
      }
    };

    onMounted(() => {
      if (loginEmail.value === null) {
        // Redirect to Forgot password view, if no email parameter found
        return router.push(ROUTE_PASSWORD_RESET_FORGOT_PASSWORD);
      }
    });

    return {
      loginEmail,
      handleResendLink,
      isMobile,
    };
  },
};
</script>

<style scoped>
.container {
  min-height: calc(100vh - 2 * var(--header-default--height));
  display: flex;
  align-items: center;
}
.content {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 60px;
}

@media (max-width: 1200px) {
  .container {
    min-height: inherit;
  }
  .content {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 60px;
  }
}
</style>
